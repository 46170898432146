import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import Button from "./button";
import { Link } from "react-scroll";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "about-creative.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto fadeup-animation" id="about-us">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              We recreate history
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Slavonic Craft LLC is a leading producer of luxury furniture in
              the Louis XVI style. Our main representatives are highly respected
              furniture carvers and a cabinetmaker who are widely appreciated
              throughout Europe.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Thanks to the experience and mastery we have acquired, Slavonic
              Craft LLC specializes in the restoration of prestigious furniture
              dating back to the 1700s and 1800s. Utilizing techniques from
              these periods, we restore furniture to its original splendor.
            </p>
            <div className="flex items-start">
              <Link to="contact-footer" smooth>
                <Button
                  label="RECREATE HISTORY WITH US"
                  link="#contact"
                  size="lg"
                  icon
                />
              </Link>
            </div>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Antique Furniture"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
