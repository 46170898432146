import React from "react";

export const PlayButton = () => {
  return (
    <svg
      className="w-32 h-32 mr-2 absolute"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 160"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0H180C191.046 0 200 8.954 200 20V140C200 151.046 191.046 160 180 160H20C8.954 160 0 151.046 0 140V20C0 8.954 8.954 0 20 0ZM91.7 112.2L130.6 86.2C132.696 84.834 133.96 82.502 133.96 80C133.96 77.498 132.696 75.166 130.6 73.8L91.7 47.8C89.403 46.248 86.438 46.089 83.988 47.387C81.539 48.685 80.005 51.228 80 54V106C80.005 108.772 81.539 111.315 83.988 112.613C86.438 113.911 89.403 113.752 91.7 112.2Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4662 80C59.4662 58.8291 77.6136 41.6667 99.9995 41.6667C110.75 41.6667 121.059 45.7054 128.661 52.8942C136.262 60.0831 140.533 69.8334 140.533 80C140.533 101.171 122.386 118.333 99.9995 118.333C77.6136 118.333 59.4662 101.171 59.4662 80ZM92.5821 96.4068L116.213 82.4534C117.097 81.9178 117.632 80.9932 117.632 80C117.632 79.007 117.097 78.0822 116.213 77.5468L92.5009 63.5934C91.567 63.0365 90.3872 63.0023 89.419 63.5038C88.4507 64.0055 87.8461 64.9642 87.8397 66.0084V93.9918C87.8266 95.0613 88.4424 96.0493 89.4381 96.5563C90.4339 97.0634 91.645 97.0058 92.5821 96.4068Z"
        fill="white"
      />
    </svg>
  );
};
