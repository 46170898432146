import React from "react";
import Divider from "../images/divider.svg";

const Metrics = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col">
          <div className="grid md:grid-cols-12 grid-cols-1 lg:gap-20 gap-8 md:self-center">
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                30+
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                CLIENTS
              </p>
            </div>
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                800+
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                HOURS DEDICATED <br></br>TO EACH CLIENT
              </p>
            </div>
            <div className="md:col-span-4 flex xl:flex-row md:flex-col flex-row xl:gap-4 md:gap-0 gap-4 xl:items-center md:items-start items-center">
              <p className="font-display md:text-display-xl text-display-lg font-normal">
                100%
              </p>
              <p className="text-body-sm font-normal tracking-wider pt-2">
                CLIENT <br></br>SATISFACTION
              </p>
            </div>
          </div>
          <div className="md:mt-20 md:pb-12 mt-12 pb-12 pointer-events-none">
            <img src={Divider} className="w-[100%] h-auto " />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Metrics;
