import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "react-scroll";
import Divider from "../images/divider.svg";
import Button from "./button";

const Ceiling = () => {
  const data = useStaticQuery(graphql`
    {
      ceiling: file(relativePath: { eq: "ceiling.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto" id="ceiling">
        <div className="grid lg:grid-cols-12 grid-cols-1 gap-8 lg:gap-20 lg:py-12 items-center">
          <div className="lg:col-span-5 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.ceiling)}
              alt="Antique Wooden Ceiling with Sculptures"
              className="rounded-[12px] drop-shadow-md"
            />
          </div>
          <div className="lg:col-span-7 flex flex-col gap-6">
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-2 lg:pb-4 text-center">
              Timeless luxury with bespoke antique wooden ceilings
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700 text-justify">
              Enter a world where every detail exudes sophistication and
              prestige with our crafted wooden ceilings. With our bespoke
              service, every detail is meticulously sculpted to perfection,
              embodying a narrative of sophistication and historical charm.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700 text-justify">
              We design pieces for cathedral-style libraries, reflecting your
              preferred wood, color, and even including sculptures. Elevate your
              space to new heights of luxury and refinement. Contact us now to
              discover our vast amount of options and to adorn your surroundings
              with the prestige they deserve.
            </p>
            <div className="flex justify-end">
              <Link to="contact-footer" smooth>
                <Button
                  label="Transform Your Home NOW"
                  link="#contact"
                  size="lg"
                  icon
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="md:mt-20 md:pb-12 mt-12 pb-12 pointer-events-none">
          <img
            src={Divider}
            className="w-[100%] h-auto "
            alt="Artistic divider symbol"
          />
        </div>
      </div>
    </div>
  );
};
export default Ceiling;
