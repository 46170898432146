import React from "react";
import ReactPlayer from "react-player/lazy";
import { useStaticQuery, graphql } from "gatsby";

import CtaButton from "../images/cta-button.svg";
import { PlayButton } from "./playbutton";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      vidthumb: file(relativePath: { eq: "vidthumb.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div className="md:mt-12 fadeup-animation">
      <div className="container mx-auto md:grid grid-cols-2 md:gap-8">
        <div className="flex flex-col xl:px-10 items-center text-center gap-6 md:py-10 py-7 order-last">
          <h1 className="font-display md:text-display-2xl text-display-lg">
            Make <span className="italic">your home</span> an ode to art
          </h1>
          <p className="col-span-8 md:text-body-xl mb-8 text-body-lg font-light text-neutral-700 max-w-[800px]">
            Elevate your space with handcrafted furniture masterpieces, each
            piece showcasing expert craftsmanship and timeless beauty.
          </p>
        </div>
        <div className="player-wrapper">
          {console.log(data.toString())}
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=q4lPt8iFEek"
            controls
            playing
            playIcon={<PlayButton />}
            light={
              <GatsbyImage
                image={getImage(data.vidthumb.childImageSharp.gatsbyImageData)}
                alt="Furniture Video Thumbnail"
                className="rounded-xl"
                width="100%"
                height="100%"
              />
            }
            width="100%"
            height="100%"
          />
          <a
            href="mailto:andrzejslobrow@gmail.com?subject=Client%20Inquiry"
            className="md:block hidden"
          >
            <img
              src={CtaButton}
              alt="Get in touch"
              className="absolute xl:-left-8 lg:left-[84%] md:left-[82%] right-[2%] -bottom-20 md:w-24 w-20"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
