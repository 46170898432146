import React from "react";

import ConsulationIcon from "../images/service-icons/consultation.svg";
import CustomizationIcon from "../images/service-icons/customization.svg";
import AuthenticIcon from "../images/service-icons/authentic.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  return (
    <div>
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-20 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="OUR SERVICES" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                We have <span className="italic">decades </span>
                of <br />
                professional experience
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ConsulationIcon}
              title="Expert Consultation"
              description="We provide personalized expertise on antique furniture styles, historical periods, and intricate design elements, guiding you through the rich tapestry of furniture history to find the perfect piece for your space."
            />
            <ServiceItem
              icon={CustomizationIcon}
              title="Custom Sizes & Configuration"
              description="We offer customization options to tailor the reproduced furniture to the client's specific preferences, including choice of wood, finish, and upholstery materials."
            />
            <ServiceItem
              icon={AuthenticIcon}
              title="Authentic Recreation"
              description="We recreate antique furniture pieces with precision and attention to detail for historical accuracy. Handmade in Central Europe from old, solid walnut, chestnut, and other selected species of hardwood."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
