import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import WorkItem from "./workItem";
import Eyebrow from "./eyebrow";
import Button from "./button";
import { Link } from "react-scroll";

const Process = () => {
  const data = useStaticQuery(graphql`
    {
      allWorksJson {
        nodes {
          id
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 592
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="container mx-auto" id="our-process">
        <div className="flex flex-col gap-12 lg:pt-24 md:pt-18 pb-0 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-10 items-center">
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow label="OUR PROCESS" id="our-process" />
                <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
                  Crafting timeless furniture with{" "}
                  <span className="italic">detail focused</span> process
                </h3>
              </div>
              {console.log(data.allWorksJson)}
              {data.allWorksJson.nodes.slice(0, 1).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                  description={node.description}
                />
              ))}
              <div className="md:flex hidden items-start">
                <Link to="contact-footer" smooth>
                  <Button
                    label="BUILD FURNITURE FULL OF DETAIL"
                    link="#contact"
                    size="lg"
                    icon
                  />
                </Link>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {data.allWorksJson.nodes.slice(1, 3).map((node) => (
                <WorkItem
                  key={node.id}
                  image={getImage(node.image)}
                  title={node.title}
                  description={node.description}
                />
              ))}
            </div>
            <div className="xl:hidden flex items-start">
              <Link to="contact-footer" smooth>
                <Button
                  label="BUILD FURNITURE FULL OF DETAIL"
                  link="#contact"
                  size="lg"
                  icon
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
